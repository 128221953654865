import { useState } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { DateTime } from 'luxon';

import WithActions from '@/engine/decorators/with-actions';

import DropDownItem from '@/components/drop-down-item';
import Icon from '@/components/icon';

import {
  ACCOUNT_STATUS_BILL_FAIL,
  ACCOUNT_STATUS_CANCELLED,
  ACCOUNT_STATUS_EXPIRED,
  ACCOUNT_STATUS_FREE,
  ACCOUNT_STATUS_PAID,
  ACCOUNT_STATUS_TRIAL,
} from '@/shared/constants';

const getAccountStatusText = (accountStatus) => {
  switch (accountStatus) {
    case ACCOUNT_STATUS_FREE:
      return 'Free';
    case ACCOUNT_STATUS_TRIAL:
      return 'Trial';
    case ACCOUNT_STATUS_PAID:
      return 'Paid';
    case ACCOUNT_STATUS_CANCELLED:
      return 'Cancelled';
    case ACCOUNT_STATUS_EXPIRED:
      return 'Expired';
    case ACCOUNT_STATUS_BILL_FAIL:
      return 'Bill Fail';
    default:
      return 'Unknown';
  }
};

const AdminUserListItem = ({ user, publish }) => {
  const [showActionsDropDown, setShowActionsDropDown] = useState(false);

  const toggleItemActions = () => {
    setShowActionsDropDown(!showActionsDropDown);
  };

  const renderEmail = () => {
    if (user.emailVerified) {
      return user.email;
    }

    return (
      <>
        <i>{user.email}</i>
        <span className="text-muted">*</span>
      </>
    );
  };

  const actionMetaData = { userId: user.id, email: user.email };

  const actionsClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const openUser = () => {
    publish({ event: 'openUser', userId: user.id });
  };

  return (
    <tr onClick={openUser}>
      <td>{user.fullName}</td>
      <td>{renderEmail()}</td>
      <td>{getAccountStatusText(user.accountStatus)}</td>
      <td>{DateTime.fromISO(user.createDate).setLocale('en-NZ').toLocaleString(DateTime.DATETIME_MED)}</td>
      <td>{user.activationDate ? DateTime.fromISO(user.activationDate).setLocale('en-NZ').toLocaleString(DateTime.DATETIME_MED) : ''}</td>
      <td>{user.lastLoginDate ? DateTime.fromISO(user.lastLoginDate).setLocale('en-NZ').toLocaleString(DateTime.DATETIME_MED) : '' }</td>
      <td onClick={actionsClicked}>
        <span className="nowrap">
          <Dropdown
            isOpen={showActionsDropDown}
            toggle={toggleItemActions}
            className="d-none d-md-inline-block"
          >
            <DropdownToggle
              className="btn-match-input btn-none"
              title="Item actions"
            >
              <Icon icon="more_vert" />
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu--overscroll">
              {!user.activationDate
                ? (
                  <DropDownItem id="admin-user-activate" actionMetaData={actionMetaData}>
                    <Icon icon="toggle_off_outline" className="mr-2" />
                    Activate User
                  </DropDownItem>
                )
                : ''}

              {user.isFree ? (
                <DropDownItem id="admin-user-set-paid" actionMetaData={actionMetaData}>
                  <Icon icon="photo_camera_front" className="mr-2" />
                  Set to Paid
                </DropDownItem>
              ) : (
                <DropDownItem id="admin-user-set-free" actionMetaData={actionMetaData}>
                  <Icon icon="photo_camera_front" className="mr-2" />
                  Set to Free
                </DropDownItem>
              )}
              <DropDownItem id="admin-user-imp" actionMetaData={actionMetaData}>
                <Icon icon="photo_camera_front" className="mr-2" />
                Impersonate User
              </DropDownItem>
              <DropDownItem id="admin-user-remove" actionMetaData={actionMetaData}>
                <Icon icon="remove_circle_outline" className="text-danger mr-2" />
                Remove User
              </DropDownItem>
            </DropdownMenu>
          </Dropdown>
        </span>
      </td>
    </tr>
  );
};

export default WithActions(AdminUserListItem);
